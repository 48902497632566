// @ts-check
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import { Typography, makeStyles, Grid } from "@material-ui/core"
import SEO from "../components/seo"
import { DocMenu } from "./DocMenu"

type DocPageProps = {} //  & PageProps<BlogPostQuery>

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      //marginLeft: "1rem",
      //marginRight: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "24px",
    },

    padding: "0 24px",
    [theme.breakpoints.up("lg")]: {
      width: "70%",
    },
  },
  content: {
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "10px",
      paddingBottom: "30px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "70%",
    },
    "& .tocTitle": {
      textAlign: "center",
      marginBottom: 0,
      maxWidth: "400px",
      backgroundColor: theme.palette.primary.dark,
      color: "#f5f5f5",
      paddingTop: "4px",
      paddingBottom: "4px",
    },
    "& .table-of-contents": {
      backgroundColor: "#F5F5F5",
      maxWidth: "400px",
      paddingTop: "2px",
      paddingBottom: "2px",
    },
    "& .table-of-contents > ul > li > p": {
      marginTop: 0,
      marginBottom: "2px",
    },
    "& .table-of-contents > ul": {
      listStyleType: "none",
      paddingLeft: "15px",
    },
    "& .table-of-contents > ul > li > ul": {
      marginBottom: "8px",
      listStyleType: "none",
      paddingLeft: "16px",
    },
    "& .table-of-contents > ul > li > ul > li > a": {
      color: "#658DAE",
      fontSize: "0.8rem",
    },
    "& thead": {
      textAlign: "left",
    },
    "& tr > th > p": {
      marginBottom: "0.125rem",
      marginTop: "0.125rem",
    },
    "& tr > td": {
      paddingRight: "15px",
    },
    "& tr > th": {
      paddingRight: "15px",
    },
    "& tr > td > p": {
      marginBottom: "0.125rem",
      marginTop: "0.125rem",
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
    "& a:hover": {
      color: theme.palette.primary.dark,
    },
    "& ul": {
      listStyleType: "circle",
    },
    "& h2": {
      marginBottom: "0.25rem",
    },
    "& h3": {
      marginBottom: "0.25rem",
    },
    "& p": {
      marginTop: 0,
    },
  },
  title: {
    margin: "0",
    paddingTop: "1.5rem",
  },
}))

const DocPage: React.FC<DocPageProps> = ({
  data,
  pageContext: { pathname },
}: any) => {
  const { markdownRemark: doc } = data
  const classes = useStyles()

  return (
    <>
      <Layout dark>
        <Grid container>
          <Grid item xs={12} md={3}>
            <DocMenu active={doc.frontmatter} />
          </Grid>
          <Grid item xs={12} md={9}>
            <div className={classes.container}>
              <Typography variant="h1" className={classes.title}>
                {doc.frontmatter.title}
              </Typography>

              <div
                dangerouslySetInnerHTML={{
                  __html: doc.html,
                }}
                className={classes.content}
              />
            </div>
          </Grid>
        </Grid>

        <SEO
          general={{
            title: doc.frontmatter.title,
            contentType: "article",
            description: doc.frontmatter.description,
            path: pathname,
            image: "todo",
          }}
        />
      </Layout>
    </>
  )
}

export default DocPage

export const documentationPageQuery = graphql`
  query DocumentationPageQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        slug
      }
      html
    }
  }
`
