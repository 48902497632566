import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { groupBy, sortBy } from "lodash-es"
import clx from "classnames"
import MenuList from "@material-ui/core/MenuList"
import MenuItem from "@material-ui/core/MenuItem"
import Link from "@material-ui/core/Link"
import Drawer from "@material-ui/core/Drawer"
import { makeStyles } from "@material-ui/core/styles"
import ListSubheader from "@material-ui/core/ListSubheader"
import TranslateIcon from "@material-ui/icons/Translate"
import Button from "@material-ui/core/Button"

export type DocMenuProps = {
  active: {
    slug
    title
  }
}

const useStyles = makeStyles(theme => ({
  menuList: {
    background: "#fff",
    marginLeft: "24px",
    marginRight: "12px",
  },
  a: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.dark,
    },
  },
  category: {
    paddingLeft: "5px",
    lineHeight: "36px",
    //fontSize: "1.4rem",
    //fontWeight: "bold",
  },
  categoryIcon: {
    paddingLeft: "5px",
    verticalAlign: "middle",
    lineHeight: "36px",
  },

  menu: {
    // background: "white",
  },
  active: {
    fontWeight: "bold",
    // background: "blue",
  },
  hideSmDown: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hideMdUp: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mobileMenuButton: {
    margin: "1rem",
  },
}))

export const DocMenu = (props: DocMenuProps) => {
  const classes = useStyles()
  const [toggle, setToggle] = useState(false)

  const data = useStaticQuery(graphql`
    query DocMenu {
      allMarkdownRemark(
        filter: { frontmatter: { layout: { eq: "documentation" } } }
      ) {
        edges {
          node {
            frontmatter {
              slug
              menu
              menuCategory
            }
          }
        }
      }
    }
  `)

  const categories = groupBy(
    sortBy(data.allMarkdownRemark.edges, edge => edge.node.frontmatter.menu),
    edge => edge.node.frontmatter.menuCategory
  )

  const CustomMenuItem = node => {
    const { slug, menu } = node.frontmatter

    // active menu item
    const className =
      slug === props.active.slug
        ? clx(classes.menu, classes.active)
        : classes.menu

    return (
      <Link href={`/documentation${slug}`} className={classes.a}>
        <MenuItem className={className}>{menu}</MenuItem>
      </Link>
    )
  }

  const DocumentationMenu = (
    <MenuList className={classes.menuList}>
      <ListSubheader disableGutters className={classes.category}>
        🚀 Getting Started
      </ListSubheader>
      {categories["Getting Started"].map(edge => CustomMenuItem(edge.node))}

      <ListSubheader disableGutters className={classes.category}>
        <TranslateIcon className={classes.categoryIcon} /> Translator
      </ListSubheader>
      {categories["translator"].map(edge => CustomMenuItem(edge.node))}

      <ListSubheader disableGutters className={classes.category}>
        💻 Project integration (CLI)
      </ListSubheader>
      {categories["cli"].map(edge => CustomMenuItem(edge.node))}

      <ListSubheader disableGutters className={classes.category}>
        📄 Formats
      </ListSubheader>
      {categories["formats"].map(edge => CustomMenuItem(edge.node))}

      <ListSubheader disableGutters className={classes.category}>
        📚 Resources
      </ListSubheader>
      {categories["resources"].map(edge => CustomMenuItem(edge.node))}

      {/*data.allMarkdownRemark.edges.map(edge => {
  const { slug, menu } = edge.node.frontmatter
  // active menu item
  const className =
    slug === props.active.slug
      ? clx(classes.menu, classes.active)
      : classes.menu

  return (
    <Link href={`/documentation${slug}`} className={classes.a}>
      <MenuItem className={className}>{menu}</MenuItem>
    </Link>
  )
})}*/}
    </MenuList>
  )

  return (
    <>
      <div className={classes.hideMdUp}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setToggle(!toggle)}
          className={classes.mobileMenuButton}
        >
          More Docs
        </Button>
        <Drawer
          anchor="left"
          transitionDuration={700}
          open={toggle}
          onClose={() => setToggle(false)}
          variant="temporary"
        >
          {DocumentationMenu}
        </Drawer>
      </div>
      <div className={classes.hideSmDown}>{DocumentationMenu}</div>
    </>
  )
}
